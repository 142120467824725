import React from "react";

import async from "../components/Async";

import {
    Bell,
    File,
    List,
    Monitor,
    ToggleLeft,
    Sliders,
    Users,
    FilePlus,
    LogOut,
    ShoppingBag,
} from "react-feather";

import { useTranslation } from "react-i18next";

// Auth components
// const SignIn = async(() => import("../pages/auth/SignIn"));
// const SignUp = async(() => import("../pages/auth/SignUp"));
// const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
// const Page404 = async(() => import("../pages/auth/Page404"));
// const Page500 = async(() => import("../pages/auth/Page500"));

const SignIn = async(() => import("../pages/views/auth/SignIn"));
const SignUp = async(() => import("../pages/views/auth/SignUp"));
const SignOut = async(() => import("../pages/views/auth/SignOut"));
const Page404 = async(() => import("../pages/views/auth/Page404"));
const Page500 = async(() => import("../pages/views/auth/Page500"));

// Components components
// const Alerts = async(() => import("../pages/components/Alerts"));
// const Avatars = async(() => import("../pages/components/Avatars"));
// const Badges = async(() => import("../pages/components/Badges"));
// const Buttons = async(() => import("../pages/components/Buttons"));
// const Cards = async(() => import("../pages/components/Cards"));
// const Chips = async(() => import("../pages/components/Chips"));
// const Dialogs = async(() => import("../pages/components/Dialogs"));
// const ExpPanels = async(() => import("../pages/components/ExpansionPanels"));
// const Lists = async(() => import("../pages/components/Lists"));
// const Menus = async(() => import("../pages/components/Menus"));
// const Pagination = async(() => import("../pages/components/Pagination"));
// const Progress = async(() => import("../pages/components/Progress"));
// const Snackbars = async(() => import("../pages/components/Snackbars"));
// const Tooltips = async(() => import("../pages/components/Tooltips"));

// Forms components
// const Pickers = async(() => import("../pages/forms/Pickers"));
// const SelectionCtrls = async(() => import("../pages/forms/SelectionControls"));
// const Selects = async(() => import("../pages/forms/Selects"));
// const TextFields = async(() => import("../pages/forms/TextFields"));
// const Dropzone = async(() => import("../pages/forms/Dropzone"));
// const Editors = async(() => import("../pages/forms/Editors"));

// Icons components
// const MaterialIcons = async(() => import("../pages/icons/MaterialIcons"));
// const FeatherIcons = async(() => import("../pages/icons/FeatherIcons"));

// Pages components
const Blank = async(() => import("../pages/pages/Blank"));
// const InvoiceDetails = async(() => import("../pages/pages/InvoiceDetails"));
// const InvoiceList = async(() => import("../pages/pages/InvoiceList"));
// const Orders = async(() => import("../pages/pages/Orders"));
// const Pricing = async(() => import("../pages/pages/Pricing"));
// const Profile = async(() => import("../pages/pages/Profile"));
// const Settings = async(() => import("../pages/pages/Settings"));
// const Tasks = async(() => import("../pages/pages/Tasks"));
// const Projects = async(() => import("../pages/pages/Projects"));
// const Calendar = async(() => import("../pages/pages/Calendar"));

// Tables components
// const SimpleTable = async(() => import("../pages/tables/SimpleTable"));
// const AdvancedTable = async(() => import("../pages/tables/AdvancedTable"));

// Chart components
// const Chartjs = async(() => import("../pages/charts/Chartjs"));

// Maps components
// const GoogleMaps = async(() => import("../pages/maps/GoogleMaps"));
// const VectorMaps = async(() => import("../pages/maps/VectorMaps"));

const AutoManual = async(() => import("../pages/views/controller/AutoManual"));
const GroupPolicy = async(
    () => import("../pages/views/controller/GroupPolicy")
);
const ParamsSystemPolicy = async(
    () => import("../pages/views/controller/ParamsSystemPolicy")
);
const SidebarSystemPolicy = async(
    () => import("../pages/views/controller/SidebarSystemPolicy")
);

const GroupGraph = async(() => import("../pages/views/monitor/GroupGraph"));
const MonitorTable = async(() => import("../pages/views/monitor/MonitorTable"));
const SystemGraph = async(() => import("../pages/views/monitor/SystemGraph"));
const LogTable = async(() => import("../pages/views/monitor/LogTable"));

const ClaimRegistration = async(
    () => import("../pages/views/claim/ClaimRegistration")
);
const ClaimManagement = async(
    () => import("../pages/views/claim/ClaimManagement")
);

const RegisterStaff = async(() => import("../pages/views/claim/RegisterStaff"));
const RegisterTenant = async(
    () => import("../pages/views/claim/RegisterTenant")
);

const AlertManagement = async(
    () => import("../pages/views/alert/AlertManagement")
);
const AlertSettings = async(() => import("../pages/views/alert/AlertSettings"));
// const dashboardsRoutes = {
//   id: "Dashboard",
//   path: "/dashboard",
//   header: "Pages",
//   icon: <Sliders />,
//   containsHome: true,
//   children: [
//     {
//       path: "/dashboard/default",
//       name: "Default",
//       component: Default
//     },
//     {
//       path: "/dashboard/analytics",
//       name: "Analytics",
//       component: Analytics
//     }
//   ],
//   component: null
// };

// const pagesRoutes = {
//   id: "Pages",
//   path: "/pages",
//   icon: <Layout />,
//   children: [
//     {
//       path: "/pages/settings",
//       name: "Settings",
//       component: Settings
//     },
//     {
//       path: "/pages/pricing",
//       name: "Pricing",
//       component: Pricing
//     },
//     {
//       path: "/pages/blank",
//       name: "Blank Page",
//       component: Blank
//     }
//   ],
//   component: null
// };

// const profileRoutes = {
//   id: "Profile",
//   path: "/profile",
//   icon: <User />,
//   component: Profile,
//   children: null
// };

// const projectsRoutes = {
//   id: "Projects",
//   path: "/projects",
//   icon: <Briefcase />,
//   badge: "8",
//   component: Projects,
//   children: null
// };

// const invoiceRoutes = {
//   id: "Invoices",
//   path: "/invoices",
//   icon: <CreditCard />,
//   children: [
//     {
//       path: "/invoices",
//       name: "List",
//       component: InvoiceList
//     },
//     {
//       path: "/invoices/detail",
//       name: "Details",
//       component: InvoiceDetails
//     }
//   ],
//   component: null
// };

// const orderRoutes = {
//   id: "Orders",
//   path: "/orders",
//   icon: <ShoppingCart />,
//   component: Orders,
//   children: null
// };

// const tasksRoutes = {
//   id: "Tasks",
//   path: "/tasks",
//   icon: <CheckSquare />,
//   badge: "17",
//   component: Tasks,
//   children: null
// };

// const calendarRoutes = {
//   id: "Calendar",
//   path: "/calendar",
//   icon: <CalendarIcon />,
//   component: Calendar,
//   children: null
// };

const monitorGraphRoutes = {
    id: "monitorGraph.helmet", //"監視グラフ",
    path: "/monitorgraph",
    header: "sidebar.monitor", //"監視",
    icon: <Monitor />,
    children: [
        {
            path: "/monitorgraph/group",
            name: "monitorGraph.byGroup.helmet", //"グループ別",
            component: GroupGraph,
        },
        {
            path: "/",
            name: "monitorGraph.perSystem.helmet", //"系統別",
            component: SystemGraph,
        },
    ],
    component: null,
};

const monitorTableRoutes = {
    id: "watchlist.helmet", // "監視表",
    path: "/monitortable",
    icon: <List />,
    children: null,
    component: MonitorTable,
};

const logTableRoutes = {
    id: "spOperataion.helmet", //"SP値操作ログ",
    path: "/logtable",
    icon: <File />,
    children: null,
    component: LogTable,
};

const autoManualRoutes = {
    id: "AUTO/MANUAL",
    path: "/automanual",
    header: "autoManual.title", // "操作",
    icon: <ToggleLeft />,
    children: null,
    component: AutoManual,
};

const policyRoutes = {
    id: "operationPolicy.helmet", //"動作ポリシー",
    path: "/policy",
    icon: <Sliders />,
    children: [
        {
            path: "/policy/group",
            name: "operationPolicy.byGroup.helmet", //"グループ別",
            component: GroupPolicy,
        },
        {
            path: "/policy/system",
            name: "operationPolicy.perSystem.helmet", // "系統別",
            component: SidebarSystemPolicy,
        },
    ],
    component: null,
};

const paramsSystemPolicyRoutes = {
    id: "sidebar.systemSpecificPolicy", //"系統別ポリシー",
    path: "/policy/system/:initialGroup/:initialSystem",
    icon: <Sliders />,
    children: null,
    component: ParamsSystemPolicy,
};

const claimRoutes = {
    id: "claim.helmet", //"クレーム",
    path: "/claim",
    icon: <File />,
    children: [
        {
            path: "/claim",
            name: "claim.list.helmet",
            component: ClaimManagement,
        },
        {
            path: "/claim/registration",
            name: "claim.registration.helmet",
            component: ClaimRegistration,
        },
    ],
    component: null,
};

const RegisterTenantRoutes = {
    id: "tenantReg.helmet", // "テナント登録",
    path: "/tenant",
    icon: <ShoppingBag />,
    children: null,
    component: RegisterTenant,
};

const RegisterStaffRoutes = {
    id: "staffReg.helmet", // "スタッフ登録",
    path: "/staff",
    icon: <Users />,
    children: null,
    component: RegisterStaff,
};

const AlertRoutes = {
    id: "alert.helmet", // "アラート",
    path: "/alert",
    icon: <Bell />,
    children: [
        {
            path: "/alert",
            name: "alert.list.subTitle", // "一覧",
            component: AlertManagement,
        },
        {
            path: "/alert/settings",
            name: "alert.registration.subTitle", // "設定",
            component: AlertSettings,
        },
    ],
    component: null,
};

const logOutRoutes = {
    id: "auth.signOut.logout", //"ログアウト",
    path: "/auth/sign-out",
    header: "auth.signOut.account", //"アカウント",
    icon: <LogOut />,
    children: null,
    component: SignOut,
};

const authRoutes = {
    id: "Auth",
    path: "/auth",
    icon: <Users />,
    children: [
        {
            path: "/auth/sign-in",
            name: "Sign In",
            component: SignIn,
        },
        {
            path: "/auth/sign-up",
            name: "Sign Up",
            component: SignUp,
        },
        // {
        //   path: "/auth/reset-password-before-sign-in",
        //   name: "Reset Password",
        //   component: ResetPasswordBeforeSignIn
        // },
        // {
        //   path: "/auth/reset-password-after-sign-in",
        //   name: "Reset Password",
        //   component: ResetPasswordAfterSignIn
        // },
        {
            path: "/auth/404",
            name: "404 Page",
            component: Page404,
        },
        {
            path: "/auth/500",
            name: "500 Page",
            component: Page500,
        },
    ],
    component: null,
};

// const componentsRoutes = {
//   id: "Components",
//   path: "/components",
//   header: "Elements",
//   icon: <Grid />,
//   children: [
//     {
//       path: "/components/alerts",
//       name: "Alerts",
//       component: Alerts
//     },
//     {
//       path: "/components/avatars",
//       name: "Avatars",
//       component: Avatars
//     },
//     {
//       path: "/components/badges",
//       name: "Badges",
//       component: Badges
//     },
//     {
//       path: "/components/buttons",
//       name: "Buttons",
//       component: Buttons
//     },
//     {
//       path: "/components/cards",
//       name: "Cards",
//       component: Cards
//     },
//     {
//       path: "/components/chips",
//       name: "Chips",
//       component: Chips
//     },
//     {
//       path: "/components/dialogs",
//       name: "Dialogs",
//       component: Dialogs
//     },
//     {
//       path: "/components/expansion-panels",
//       name: "Expansion Panels",
//       component: ExpPanels
//     },
//     {
//       path: "/components/lists",
//       name: "Lists",
//       component: Lists
//     },
//     {
//       path: "/components/menus",
//       name: "Menus",
//       component: Menus
//     },
//     {
//       path: "/components/pagination",
//       name: "Pagination",
//       component: Pagination
//     },
//     {
//       path: "/components/progress",
//       name: "Progress",
//       component: Progress
//     },
//     {
//       path: "/components/snackbars",
//       name: "Snackbars",
//       component: Snackbars
//     },
//     {
//       path: "/components/tooltips",
//       name: "Tooltips",
//       component: Tooltips
//     }
//   ],
//   component: null
// };

// const formsRoutes = {
//   id: "Forms",
//   path: "/forms",
//   icon: <CheckSquare />,
//   children: [
//     {
//       path: "/forms/pickers",
//       name: "Pickers",
//       component: Pickers
//     },
//     {
//       path: "/forms/selection-controls",
//       name: "Selection Controls",
//       component: SelectionCtrls
//     },
//     {
//       path: "/forms/selects",
//       name: "Selects",
//       component: Selects
//     },
//     {
//       path: "/forms/text-fields",
//       name: "Text Fields",
//       component: TextFields
//     },
//     {
//       path: "/forms/dropzone",
//       name: "Dropzone",
//       component: Dropzone
//     },
//     {
//       path: "/forms/editors",
//       name: "Editors",
//       component: Editors
//     }
//   ],
//   component: null
// };

// const tablesRoutes = {
//   id: "Tables",
//   path: "/tables",
//   icon: <List />,
//   children: [
//     {
//       path: "/tables/simple-table",
//       name: "Simple Table",
//       component: SimpleTable
//     },
//     {
//       path: "/tables/advanced-table",
//       name: "Advanced Table",
//       component: AdvancedTable
//     }
//   ],
//   component: null
// };

// const iconsRoutes = {
//   id: "Icons",
//   path: "/icons",
//   icon: <Heart />,
//   children: [
//     {
//       path: "/icons/material-icons",
//       name: "Material Icons",
//       component: MaterialIcons
//     },
//     {
//       path: "/icons/feather-icons",
//       name: "Feather Icons",
//       component: FeatherIcons
//     }
//   ],
//   component: null
// };

// const chartRoutes = {
//   id: "Charts",
//   path: "/charts",
//   icon: <PieChart />,
//   component: Chartjs,
//   children: null
// };

// const mapsRoutes = {
//   id: "Maps",
//   path: "/maps",
//   icon: <Map />,
//   children: [
//     {
//       path: "/maps/google-maps",
//       name: "Google Maps",
//       component: GoogleMaps
//     },
//     {
//       path: "/maps/vector-maps",
//       name: "Vector Maps",
//       component: VectorMaps
//     }
//   ],
//   component: null
// };

// const presentationRoutes = {
//   id: "Presentation",
//   path: "/",
//   header: "Docs",
//   icon: <Monitor />,
//   component: Presentation,
//   children: null
// };

// const documentationRoutes = {
//   id: "Getting Started",
//   path: "/documentation",
//   icon: <BookOpen />,
//   component: Docs,
//   children: null
// };

// const changelogRoutes = {
//   id: "Changelog",
//   path: "/changelog",
//   badge: "v1.1.0",
//   icon: <List />,
//   component: Changelog,
//   children: null
// };

// This route is not visisble in the sidebar
const privateRoutes = {
    id: "Private",
    path: "/private",
    component: Blank,
    children: null,
};

export const dashboard = [
    // testResultRoutes,
    // ResultRoutes,
    // editAnnotationSetRoutes,
    // editTrainingDataRoutes,
    // editTestDataRoutes,
    // addTrainRoutes,
    // addTestRoutes,
    // photosRoutes,
    // annotateRoutes,
    // bundleRoutes,
    // moduleRoutes,
    // trainRoutes,
    // testRoutes,
    // settingRoutes,
    // dashboardsRoutes,
    // pagesRoutes,
    // profileRoutes,
    // projectsRoutes,
    // orderRoutes,
    // invoiceRoutes,
    monitorGraphRoutes,
    monitorTableRoutes,
    logTableRoutes,
    autoManualRoutes,
    policyRoutes,
    // logOutRoutes,
    paramsSystemPolicyRoutes,
    claimRoutes,
    RegisterTenantRoutes,
    RegisterStaffRoutes,
    AlertRoutes,
    // tasksRoutes,
    // calendarRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    // tablesRoutes,
    // iconsRoutes,
    // mapsRoutes,
    // presentationRoutes,
    // documentationRoutes,
    // changelogRoutes,
    privateRoutes,
];

export const auth = [authRoutes, logOutRoutes];

export default [
    // testAPIRoutes,
    // photosRoutes,
    // annotateRoutes,
    // bundleRoutes,
    // moduleRoutes,
    // trainRoutes,
    // testRoutes,
    // settingRoutes,
    // dashboardsRoutes,
    // pagesRoutes,
    // profileRoutes,
    // projectsRoutes,
    // orderRoutes,
    // invoiceRoutes,
    monitorGraphRoutes,
    monitorTableRoutes,
    logTableRoutes,
    autoManualRoutes,
    policyRoutes,
    claimRoutes,
    RegisterTenantRoutes,
    RegisterStaffRoutes,
    AlertRoutes,
    logOutRoutes,
    // paramsSystemPolicyRoutes,
    // tasksRoutes,
    // calendarRoutes,
    // authRoutes,
    // componentsRoutes,
    // chartRoutes,
    // formsRoutes,
    // tablesRoutes,
    // iconsRoutes,
    // mapsRoutes,
    // presentationRoutes,
    // documentationRoutes,
    // changelogRoutes
];
